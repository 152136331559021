import {
  BIconCardChecklist, BIconChatLeftText, BIconHouseDoor, BIconPeople,
} from 'bootstrap-vue'
import { PowerIcon } from 'vue-feather-icons'
import modules from '@/modules'

const moduleNavigations = []
Object.keys(modules).forEach(key => {
  if (modules[key].navigation) {
    modules[key].navigation.forEach(item => {
      moduleNavigations.push(item)
    })
  }
})

export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: BIconHouseDoor,
  },
  {
    title: 'Profile',
    route: 'profile-general',
    icon: BIconPeople,
  },
  {
    title: 'Documents',
    route: 'document-general',
    icon: BIconCardChecklist,
  },
  ...moduleNavigations,

  // {
  //   title: 'Economic Calendar',
  //   route: 'economic-calendar',
  //   icon: 'FileIcon',
  // },
  {
    title: 'Tickets',
    route: 'tickets',
    icon: BIconChatLeftText,
  },
  // {
  //   title: 'Metatrader',
  //   route: 'metatrader',
  //   icon: 'MapIcon',
  // },
  {
    title: 'logout',
    route: 'logout',
    icon: PowerIcon,
  },
]
